<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-overlay :show="!ready">
                <div class="d-flex flex-column align-items-center" v-if="ready">
                    <h4>Membership Completion</h4>
                    <div class="align-items-center">
                        <img src="@/assets/images/client_logo_alpha.webp" alt height="150" />
                    </div>
        
                    <h3>Hi {{ completion_data.first_name }}</h3>
                    <span>Please read through the membership agreement and confirm your acceptance to join</span>
                    <span>A copy will be mailed to you on acceptance</span>
                    
                    <hr />
                    <div>
                        <!--- <iframe id="pdf-iframe" :src="completion_data.agreement_src"></iframe> -->
                        <webix-ui :config="ui"></webix-ui>
                        
                        <div class="modal-footer mt-4">
                            <b-button @click="showModal" variant="primary">Confirm Membership</b-button>
                        </div>
                    </div>
                </div>
                
            </b-overlay>
        </div>

        <b-modal v-model="modalVisible" hide-footer>
            <template #modal-title>
                Confirm Membership
            </template>
            <div>
                <h5>
                    I, {{ completion_data.first_name }} {{ completion_data.last_name }} (ID No. {{ completion_data.id_no }}),
                    confirm that I accept the End User Agreement.
                </h5>
            </div>
            <div>
                <b-alert show variant="info">Please upload a picture of your ID or Drivers License to complete this agreement</b-alert>
                <uploader
                    :options="renderUploadOptions"
                    :autoStart="false"
                    :ref="'id_upload'"
                    class="uploader-example mt-3"
                    @file-added="onFileAdded"
                >
                <uploader-unsupport></uploader-unsupport>
                <uploader-drop>
                    <p>Drop file here to upload or</p>
                    <uploader-btn type="button" :single="false">Select file</uploader-btn>
                </uploader-drop>
                <uploader-list></uploader-list>
                </uploader>
            </div>
            <div class="mt-3 d-flex justify-content-end">
                <b-button variant="danger" @click="modalVisible = false">Cancel</b-button>
                <b-button v-show="idAdded" variant="primary" class="ml-2" @click="confirmMembership">Complete Membership</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";
export default {
    data: () => ({
        ready: false,
        modalVisible: false,
        idAdded: false,
    }),
    computed: {
        ...crmComputed,
        ui() {
            const viewportWidth = window.innerWidth;
            let config = {
                rows:[
                    { 
                        view:"pdfbar", 
                        id:"toolbar", 
                        width: (viewportWidth > 768 ? 600 : 350),
                    },
                    { 
                        view:"pdfviewer", 
                        id:"pdf",
                        toolbar:"toolbar",
                        height: (viewportWidth > 768 ? 600 : 350), 
                        url:"binary->" + this.completion_data.agreement_src
                    }
                ]
            }
            return config;
        },
        renderUploadOptions() {
            let url = process.env.VUE_APP_API_BASEURL + "api/crm/upload_id";
            let options = {
                target: url,
                query: {
                    client_id: "",
                    token: this.$route.params.token,
                },
                testChunks: false,
                simultaneousUploads: 1, 
                fileParameterName: 'image', //Set the upload post param which will contain the binary payload
                singleFile: true, //Limit upload to one file
            };
            return options;
        },
    },
    methods: {
        ...crmMethods,
        refreshPage() {
            window.location.reload();
        },
        onFileAdded() {
            this.idAdded = true;
        },
        showModal() {
            this.modalVisible = true;
        },
        confirmMembership() {
            this.finaliseMembership({token: this.$route.params.token}).then((response) => {
                this.handleIdUpload(response.client_id);
            });
        },
        handleIdUpload(clientId) {
            const uploaderInstance = this.$refs['id_upload'];
            const upload = uploaderInstance.uploader;
            upload.opts.query.client_id = clientId;

            upload.resume();
            upload.on('fileSuccess', function() {
                this.modalVisible = false;
                Swal.fire({
                    title: 'Account Created',
                    text: 'Your login details will be mailed to you. Please check your email.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    window.location.href = process.env.VUE_APP_UI_BASEURL;
                });
            });
        }
    },
    mounted() {
        this.getMembershipCompletionData(this.$route.params.token).then(() => {
            this.ready = true;
        });
    },
};
</script>

<style lang="scss" scoped>
.mw-500 {
    max-width: 500px !important;
}
.input-500 {
    width: 500px;
}

@media only screen and (max-width: 510px) {
    .input-500 {
        width: 300px;
    }
}
@media only screen and (max-width: 310px) {
    .input-500 {
        width: 200px;
    }
}

.pdf-view {
    width: 600px;
    height: 600px; /* Default height */
    border: none;
}

@media (max-width: 768px) {
    .pdf-view  {
        width: 300px;
        height: 300px; /* Adjust height for smaller devices */
    }
}
</style>
